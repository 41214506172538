<template>
  <div v-if="sectionCount" class="header">
    <transition name="fade" mode="out-in">
      <confirm
        v-if="confirmOpen"
        :title="'Are you sure you want to save sections?'"
        :content="'Current configuration will be updated with the changes you have made.'"
        @cancel="closeConfirm"
        @confirm="save"
      />
    </transition>
    <div class="header-title">Sections</div>
    <div class="header-item right">
      <button
        v-if="sections"
        class="button button-success"
        @click="openConfirm"
      >
        <i class="icon-floppy" />Save
      </button>
      <button v-if="sections" class="button button-primary" @click="newSection">
        <i class="icon-plus" />New Section
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '@/components/common/navigation/Confirm';

export default {
  name: 'SectionsHeader',
  components: {
    Confirm,
  },
  data() {
    return {
      confirmOpen: false,
    };
  },
  computed: {
    ...mapGetters(['sectionCount']),
    sections() {
      if (this.$route.name === 'sections') {
        return true;
      }
      return false;
    },
  },

  methods: {
    openConfirm() {
      this.confirmOpen = true;
    },
    closeConfirm() {
      this.confirmOpen = false;
    },
    save() {
      this.$store.dispatch('saveSections');
      this.closeConfirm();
    },
    newSection() {
      this.$router.push({ name: 'newSection' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/sections/Sections.scss';
</style>

<template>
  <div class="MoreTab">
    <sections-header class="header" />
    <transition name="fade" mode="out-in">
      <router-view class="panel" />
    </transition>
  </div>
</template>

<script>
import SectionsHeader from './sections/SectionsHeader';

export default {
  name: 'MoreTab',
  components: {
    SectionsHeader,
  },
  created() {
    this.$store.dispatch('getSections');
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/more-tab/MoreTab.scss';
</style>
